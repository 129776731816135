.input-container {
  /*min-height: 100px;*/
  padding: 0;
  max-width: none;
  border-bottom: solid 2px #6b6d6f !important;
  margin: 0 6px 20px;
}
.input-container.centered,
.input-container.centered .input-box {
  text-align: center;
}
.input-container .input-box {
  width: 100%;
  max-width: none;
  height: auto;
  padding: 0;
  margin: 0;
  outline: none;
  font-size: 0.88em;
  line-height: 1em;
  color: #000;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.input-container .form-control {
  padding: 0;
  height: 26px;
  display: inline;
}

.input-container label.placeholder {
  position: absolute;
  left: 26px;
  cursor: text;
  font-size: 0.88em;
  line-height: 1em;
  color: #6b6d6f;
  border: none;
  background-color: transparent;
  width: 100%;
  max-width: none;
  box-shadow: none;
  font-weight: normal;
}
.input-container.centered label.placeholder {
  left: 0;
}
.input-container label.placeholder,
.input-container span.glyphicon-search {
  pointer-events: none;
}

/* hide the label in modern browsers or when text has been entered */
.input-container label.placeholder,
.input-container input[placeholder].placeholder-state-hidden + label.placeholder,
.input-container label.placeholder.placeholder-state-hidden,
.home .search-module .search-bar.input-container span.glyphicon-search.placeholder-state-hidden {
  display: none;
}

/* input text color */
.input-container input[placeholder] {
  color: #000;
}

/* normal placeholder text color, slightly lighter than input text color */
.input-container input[placeholder]::-webkit-input-placeholder,
.input-container input[placeholder]:-moz-placeholder {
  color: #000;
}
.input-container input[placeholder]:-ms-input-placeholder {
  color: transparent;
}
.input-container input[placeholder] + label.placeholder {
  color: #000;
}

/* focused placeholder text color, even lighter than normal placeholder text color */
.input-container input[placeholder]:focus::-webkit-input-placeholder,
.input-container input[placeholder]:focus:-moz-placeholder {
  color: #000;
}
.input-container input[placeholder]:focus:-ms-input-placeholder {
  color: transparent;
}
.input-container input[placeholder]:focus + label.placeholder {
  color: #000;
}

.input-container input::-webkit-input-placeholder {
  color: #6b6d6f;
}
.input-container input::-moz-placeholder {
  color: #6b6d6f;
}
.input-container input:-ms-input-placeholder {
  color: #6b6d6f;
}
.input-container input:-moz-placeholder {
  color: #6b6d6f;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .input-container label.placeholder {
    display: inline-block;
    line-height: 1.85em;
  }
}

/*Hack to remove IE "X" */
.input-container .input-box::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
/* Small and up */
@media (min-width: 768px) {
  .input-container .input-group-addon .glyphicon-remove {
    font-size: 10px !important;
  }
}
/* Extra Small and below */
@media (max-width: 767px) {
  .input-container {
    padding: 25px 15px;
  }
  .input-container .input-box {
    width: 75%;
    font-size: 16px;
    padding-left: 0;
    padding-right: 6px;
  }
  .input-container .glyphicon {
    margin-top: 0;
    margin-right: 0;
  }
  .input-group-addon .glyphicon-remove {
    padding-right: 10px;
    margin-right: 10px;
    border-right: 3px solid #bfc1c0;
  }
  .input-group-addon .hide-buttons {
    margin-top: 14px;
  }
  .input-group-addon .hide-buttons .input-group-addon {
    padding-right: 0;
  }
  .input-group-addon label.placeholder,
  .input-group-addon label.placeholder {
    font-size: 16px;
    top: 3px;
  }
}
