.container-fluid {
  padding: 0;
}
.row {
  margin-left: 0;
  margin-right: 0;
}
.col {
  padding-left: 20px;
  padding-right: 20px;
}
.width-1920 {
  max-width: 1920px;
}
.width-1280 {
  max-width: 1280px;
}
.width-1200 {
  max-width: 1200px;
}
.width-800 {
  max-width: 800px;
}
.width-600 {
  max-width: 600px;
}
a:focus,
a:focus h3,
button:focus,
button.dropdown-toggle:focus,
li:focus,
a.video-lightbox:focus .glyphicon,
.close:focus {
  outline: none;
}
.dropdown-toggle:focus,
button.dropdown-toggle:focus {
  outline-offset: 0;
}
.focus-link a.arrow-link {
  outline-offset: -1px;
}
a:hover,
a:active,
a:visited,
a:hover h3,
a:active h3,
a:visited h3,
button:hover,
button:active,
button:visited,
.dropdown-toggle:hover,
.dropdown-toggle:active,
.dropdown-toggle:visited,
.dropdown > .dropdown-toggle:hover,
.dropdown > .dropdown-toggle:active,
.dropdown > .dropdown-toggle:visited,
li:hover,
li:active,
li:visited,
a.video-lightbox:hover .glyphicon,
a.video-lightbox:active .glyphicon,
a.video-lightbox:visited .glyphicon,
.close:hover,
.close:active,
.close:visited,
main#main-content {
  outline: none;
}

p,
ul {
  margin-bottom: 20px;
}

p.bottom {
  margin-bottom: 0;
}
/* Wrapping content in preformatted (pre) content containers */
pre.wrap-pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre=wrap;
  word-wrap: break-word;
}

/* Template styles */
#skip-to-content a {
  padding: 6px;
  position: absolute;
  top: -40px;
  left: 0px;
  color: #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-bottom-right-radius: 8px;
  background: #fff;
  -webkit-transition: top 1s ease-out, background 1s linear;
  transition: top 1s ease-out, background 1s linear;
  z-index: 2000;
}

#skip-to-content a:focus {
  position: absolute;
  left: 0px;
  top: 0px;
  outline: 0;
  -webkit-transition: top 0.1s ease-in, background 0.5s linear;
  transition: top 0.1s ease-in, background 0.5s linear;
}

@media (max-width: 840px) {
  .col.width-800 {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 640px) {
  .col.width-600 {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.font-gotham {
  font-family: 'GothamBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.font-gotham-book {
  font-family: 'GothamBook', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.font-gotham-book-medium {
  font-family: 'GothamBookMedium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.font-gotham-narrow {
  font-family: 'GothamNarrowBook', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.font-gotham-narrow-bold {
  font-family: 'GothamNarrowBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.caption {
  margin-top: 10px;
  text-indent: -8px;
  padding-left: 40px;
  padding-top: 10px;
}
.footnote {
  font-size: 0.9em;
}
sup {
  font-size: 0.8em;
}
blockquote {
  font-size: 1.4em;
  line-height: 1.25em;
  font-family: 'GothamNarrowBook', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #0066b2;
  border: none;
  padding: 0;
  text-align: center;
}
blockquote > cite {
  display: block;
  text-align: center;
  font-size: 0.63em;
  line-height: 1.4em;
  color: #000;
  padding-top: 10px;
  font-style: normal;
}
blockquote > cite > span.role {
  font-family: 'GothamNarrowBook', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
/* Small, Med, and Large devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .col {
    padding-left: 0;
    padding-right: 0;
  }
}

.text-shadow-dark {
  text-shadow: 0 0 15px rgba(0, 0, 0, 1);
}
.text-shadow-light {
  text-shadow: 0 0 15px rgba(255, 255, 255, 1);
}
.text-shadow-none {
  text-shadow: none;
}
.box-shadow {
  -webkit-box-shadow: 2px 2px 0 0 #dbdcdd;
  -moz-box-shadow: 2px 2px 0 0 #dbdcdd;
  box-shadow: 2px 2px 0 0 #dbdcdd;
}

/* Set padding to keep content from hitting the edges */
.body-content {
  padding-left: 15px;
  padding-right: 15px;
}

/* Override the default bootstrap behavior where horizontal description lists 
will truncate terms that are too long to fit in the left column 
*/
.dl-horizontal dt {
  white-space: normal;
}

/* ######## Common Styles ########## */
main#main-content {
  background-color: #f3f3f3;
}
.module {
  background-color: #fff;
  /* margin-bottom: 15px; */
  padding: 10px 20px 10px;
}
.alt-hide {
  position: absolute;
  color: transparent;
  left: -10000px;
}

.float-right {
  float: right;
}

.clear-both,
.clearboth {
  clear: both;
}

.centered {
  float: none;
  margin: 0 auto;
}
/* Fix for iOS, add this element to touch events that are not firing */
.clickable {
  cursor: pointer;
}
.svc-soc-list .svc-recommend-count,
.svc-soc-list .svc-comments-count {
  font-family: 'GothamNarrowBook', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.75em;
  color: black !important;
}
.svc-soc-list .glyphicon-thumbsup {
  top: 2px;
  left: -2px;
}
.svc-soc-list .glyphicon-comment {
  top: 6px;
  left: -2px;
}
.svc-soc-list .glyphicon-thumbsup,
.svc-soc-list .glyphicon-comment {
  color: #0066b2 !important;
}

/* Extra Small and below */
@media (max-width: 767px) {
  .centered-xs {
    float: none;
    margin: 0 auto;
  }
  /* bug fix for wrapping focus link text in mobile */
  .focus-link {
    margin-left: 30px;
    margin-right: 30px;
  }
  .focus-link a {
    display: block;
  }
  .module {
    padding: 20px 10px;
  }
  main#main-content {
    padding: 15px 10px 30px;
  }
}
/* Small devices (tablets, 768px and 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .centered-sm {
    float: none;
    margin: 0 auto;
  }
}
/* Medium devices (desktops, 992px and 1199px) */
@media (min-width: 992px) and (max-width: 1199px) {
  .centered-md {
    float: none;
    margin: 0 auto;
  }
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .centered-lg {
    float: none;
    margin: 0 auto;
  }
}

.bg-center,
.header,
.background {
  background-repeat: no-repeat;
}

.vertical-align {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
/*IE 10/11 Hack*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /*Fix for IE10 wrapping bug*/
  .vertical-align * {
    max-width: 100%;
  }
}
.vertical-align.flex-center,
.flex-center {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
}
.vertical-align.flex-end,
.flex-end {
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  -ms-flex-pack: end;
  align-items: flex-end;
}
.vertical-align.flex-start,
.flex-start {
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  -ms-flex-pack: start;
  align-items: flex-start;
}

.vertical-align.ie10-flex-center,
.ie10-flex-center {
  -ms-flex-pack: center;
}
.vertical-align.flex-end,
.ie10-flex-end {
  -ms-flex-pack: end;
}
.vertical-align.flex-start,
.ie10-flex-start {
  -ms-flex-pack: start;
}
/* Extra Small and below */
@media (max-width: 767px) {
  .no-vertical-align-xs {
    display: block;
  }
}
/* Small devices (tablets, 768px and 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .no-vertical-align-sm {
    display: block;
  }
}
/* Medium devices (desktops, 992px and 1199px) */
@media (min-width: 992px) and (max-width: 1199px) {
  .no-vertical-align-md {
    display: block;
  }
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .no-vertical-align-lg {
    display: block;
  }
}

/* Begin Icon List*/
.icon-list li {
  padding: 0 0 5px 16px;
  display: block;
  position: relative;
  line-height: 1.31em;
  margin-bottom: 0.4em;
}
p.caption:before,
.icon-list li:before {
  font-family: 'ChevronIcons';
  position: absolute;
  left: 0;
  font-size: 0.7em;
  font-weight: 200;
  top: -1px;
}
p.caption:before {
  position: relative;
  left: -7px;
}
p.caption:before,
.chevron-right li:before {
  font-family: 'GothamNarrowBook', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  content: '\203a';
}

.checkmark li:before {
  content: '\e067';
}

.arrow-right li:before {
  content: '\e092';
}
/* End Icon List*/

/* Begin Call to Action */
.focus-link {
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
}
.focus-link .theme-bg-color {
  background-color: #009dd9;
}
.focus-link a.theme-bg-color:hover,
.focus-link a.theme-bg-color:focus {
  background-color: #0066b2;
}
.focus-link a {
  color: #fff;
  padding: 12px 47px;
  font-size: 14pt;
  font-family: 'GothamNarrowBook', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.focus-link a:hover,
.focus-link a:focus {
  text-decoration: none;
}
.focus-link .glyphicon,
.arrow-link span.glyphicon,
.arrow-link-inline:after,
.arrow-link-inline span.glyphicon {
  font-size: 0.55em;
  padding-left: 6px;
  top: 1px;
  text-decoration: none !important;
  width: auto; /* Added after new chevron right to fix width */
}

.focus-link .glyphicon:hover,
.arrow-link > span.glyphicon:hover,
.arrow-link-inline span.glyphicon:hover {
  text-decoration: none !important;
}

.arrow-link.left > span.glyphicon {
  padding-right: 10px;
}
.contrast-large {
  font-size: 14pt;
  font-family: 'GothamNarrowBook', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.video-lightbox .glyphicon-play-circle {
  position: absolute;
  font-size: 4em;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 65px;
  height: 60px;
  color: #dbdcdd;
}
.video-lightbox .sr-only {
  color: #fff;
}
.video-lightbox:hover .glyphicon-play-circle {
  color: #686c67;
}

/* End - Call to Action */

/*fix for broken iomages in migrated stories*/
img[src='http://www.chevron.com'], img[src='http://www.chevron.com/']
{
  display: none !important;
}

/* .pagination line-height bugfix */
.pagination .glyphicon-chevron-right::before {
  line-height: 0.25em;
}

/* Begin - Image Styles */
.bg-cover {
  height: 690px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.icon-download {
  background-image: url('/assets/Images/icons/icon-download.png');
  background-repeat: no-repeat;
  background-position: 50%;
  height: 24px;
  width: 18px;
  margin: 0 auto;
}
/* End - Image Styles */

/* Begin - Back to Top styles*/
.back-to-top {
  position: relative;
}
.back-to-top a.arrow-link {
  font-size: 1.5em;
}
.back-to-top a.arrow-link span {
  padding: 15px;
  width: auto;
}
.back-to-top a.arrow-link span.sr-only {
  color: #000;
}
.back-to-top > div.top-container {
  text-align: center;
  margin-top: -100px;
  position: fixed;
  bottom: 25px;
  right: 15px;
  opacity: 0.9;
  z-index: 1000;
  background-color: #bfc1c0 !important;
}
.back-to-top > div.top-container:hover,
.back-to-top > div.top-container:focus {
  background-color: #686c67 !important;
}

/* End - Back to Top styles*/

.divider,
.divider-35,
.divider-50,
.divider-100 {
  background-color: #b3b3b3;
  color: #b3b3b3;
  border: 0 none;
  height: 4px;
  width: 35px;
  margin-top: 25px;
  margin-bottom: 25px;
}
.divider-extra-margin {
  margin-top: 40px;
  margin-bottom: 40px;
}
.divider-50 {
  width: 50px;
}
.divider-100 {
  width: 100px;
}
.divider-width-1920 {
  width: auto;
}
.divider-left {
  margin-left: 0;
}
.divider-right {
  margin-right: 0;
}

.social-icons-images a:hover img,
.social-icons-images a:focus img {
  opacity: 0.75;
}

.iframe-parent {
  text-align: center;
}

.search-module .search-bar .glyphicon-search {
  position: absolute;
  font-size: 2em;
  left: 39px;
  top: 71px;
  display: block;
  font-weight: 100;
}
.right-column .search-module .search-bar .glyphicon-search {
  left: 25px;
  top: 61px;
}

/* Begin - colors */

/* theme-bg-black */
.black,
.theme-text-black .theme-text-color,
.theme-text-black .theme-text-color a,
.theme-text-black.theme-text-color {
  color: #000000 !important;
}
.bg-black,
.theme-bg-black .theme-bg-color,
.theme-bg-black.theme-bg-color,
.theme-bg-color .theme-bg-light-blue .theme-bg-color,
.theme-text-black .theme-divider-color {
  background-color: #000000 !important;
}
.theme-bg-black .focus-link a.theme-bg-color:hover,
.theme-bg-black .focus-link a.theme-bg-color:focus,
.focus-link.theme-bg- a.theme-bg-color:hover,
.focus-link.theme-bg- a.theme-bg-color:focus,
.theme-bg-color .focus-link.theme-bg- a.theme-bg-color:hover,
.theme-bg-color .focus-link.theme-bg- a.theme-bg-color:focus {
  background-color: #6b6d6f !important;
}

/* theme-bg-light-blue */
.light-blue,
.theme-text-light-blue .theme-text-color,
.theme-text-light-blue .theme-text-color a,
.theme-text-light-blue.theme-text-color {
  color: #009dd9 !important;
}
.bg-light-blue,
.theme-bg-light-blue .theme-bg-color,
.theme-bg-light-blue.theme-bg-color,
.theme-bg-color .theme-bg-light-blue .theme-bg-color,
.theme-text-light-blue .theme-divider-color {
  background-color: #009dd9 !important;
}
.theme-bg-light-blue .focus-link a.theme-bg-color:hover,
.theme-bg-light-blue .focus-link a.theme-bg-color:focus,
.focus-link.theme-bg-light-blue a.theme-bg-color:hover,
.focus-link.theme-bg-light-blue a.theme-bg-color:focus,
.theme-bg-color .focus-link.theme-bg-light-blue a.theme-bg-color:hover,
.theme-bg-color .focus-link.theme-bg-light-blue a.theme-bg-color:focus {
  background-color: #0066b2 !important;
}

/* theme-bg-medium-blue */
.medium-blue,
.theme-text-medium-blue .theme-text-color,
.theme-text-medium-blue .theme-text-color a,
.theme-text-medium-blue.theme-text-color {
  color: #0066b2 !important;
}
.bg-medium-blue,
.theme-bg-medium-blue .theme-bg-color,
.theme-bg-medium-blue.theme-bg-color,
.theme-bg-color .theme-bg-medium-blue .theme-bg-color,
.theme-text-medium-blue .theme-divider-color {
  background-color: #0066b2 !important;
}
.theme-bg-medium-blue .focus-link a.theme-bg-color:hover,
.theme-bg-medium-blue .focus-link a.theme-bg-color:focus,
.focus-link.theme-bg-medium-blue a.theme-bg-color:hover,
.focus-link.theme-bg-medium-blue a.theme-bg-color:focus,
.theme-bg-color .focus-link.theme-bg-medium-blue a.theme-bg-color:hover,
.theme-bg-color .focus-link.theme-bg-medium-blue a.theme-bg-color:focus {
  background-color: #0b2d71 !important;
}

/* theme-bg-dark-blue */
.dark-blue,
.theme-text-dark-blue .theme-text-color,
.theme-text-dark-blue .theme-text-color a,
.theme-text-dark-blue.theme-text-color {
  color: #0b2d71 !important;
}
.bg-dark-blue,
.theme-bg-dark-blue .theme-bg-color,
.theme-bg-dark-blue.theme-bg-color,
.theme-bg-color .theme-bg-dark-blue .theme-bg-color,
.theme-text-dark-blue .theme-divider-color {
  background-color: #0b2d71 !important;
}
.theme-bg-dark-blue .focus-link a.theme-bg-color:hover,
.theme-bg-dark-blue .focus-link a.theme-bg-color:focus,
.focus-link.theme-bg-dark-blue a.theme-bg-color:hover,
.focus-link.theme-bg-dark-blue a.theme-bg-color:focus,
.theme-bg-color .focus-link.theme-bg-dark-blue a.theme-bg-color:hover,
.theme-bg-color .focus-link.theme-bg-dark-blue a.theme-bg-color:focus {
  background-color: #0066b2 !important;
}

/* theme-bg-light-gray */
.light-gray,
.theme-text-light-gray .theme-text-color,
.theme-text-light-gray .theme-text-color a,
.theme-text-light-gray.theme-text-color {
  color: #dbdcdd !important;
}
.bg-light-gray,
.theme-bg-light-gray .theme-bg-color,
.theme-bg-light-gray.theme-bg-color,
.theme-bg-color .theme-bg-light-gray .theme-bg-color,
.theme-text-light-gray .theme-divider-color {
  background-color: #dbdcdd !important;
}
.theme-bg-light-gray .focus-link a.theme-bg-color:hover,
.theme-bg-light-gray .focus-link a.theme-bg-color:focus,
.focus-link.theme-bg-light-gray a.theme-bg-color:hover,
.focus-link.theme-bg-light-gray a.theme-bg-color:focus,
.theme-bg-color .focus-link.theme-bg-light-gray a.theme-bg-color:hover,
.theme-bg-color .focus-link.theme-bg-light-gray a.theme-bg-color:focus {
  background-color: #8c8f93 !important;
}

/* theme-bg-medium-gray */
.medium-gray,
.theme-text-medium-gray .theme-text-color,
.theme-text-medium-gray .theme-text-color a,
.theme-text-medium-gray.theme-text-color {
  color: #8c8f93 !important;
}
.bg-medium-gray,
.theme-bg-medium-gray .theme-bg-color,
.theme-bg-medium-gray.theme-bg-color,
.theme-bg-color .theme-bg-medium-gray .theme-bg-color,
.theme-text-medium-gray .theme-divider-color {
  background-color: #8c8f93 !important;
}
.theme-bg-medium-gray .focus-link a.theme-bg-color:hover,
.theme-bg-medium-gray .focus-link a.theme-bg-color:focus,
.focus-link.theme-bg-medium-gray a.theme-bg-color:hover,
.focus-link.theme-bg-medium-gray a.theme-bg-color:focus,
.theme-bg-color .focus-link.theme-bg-medium-gray a.theme-bg-color:hover,
.theme-bg-color .focus-link.theme-bg-medium-gray a.theme-bg-color:focus {
  background-color: #6b6d6f !important;
}

/* theme-bg-dark-gray */
.dark-gray,
.theme-text-dark-gray .theme-text-color,
.theme-text-dark-gray .theme-text-color a,
.theme-text-dark-gray.theme-text-color {
  color: #6b6d6f !important;
}
.bg-dark-gray,
.theme-bg-dark-gray .theme-bg-color,
.theme-bg-dark-gray.theme-bg-color,
.theme-bg-color .theme-bg-dark-gray .theme-bg-color,
.theme-text-dark-gray .theme-divider-color {
  background-color: #6b6d6f !important;
}
.theme-bg-dark-gray .focus-link a.theme-bg-color:hover,
.theme-bg-dark-gray .focus-link a.theme-bg-color:focus,
.focus-link.theme-bg-dark-gray a.theme-bg-color:hover,
.focus-link.theme-bg-dark-gray a.theme-bg-color:focus,
.theme-bg-color .focus-link.theme-bg-dark-gray a.theme-bg-color:hover,
.theme-bg-color .focus-link.theme-bg-dark-gray a.theme-bg-color:focus {
  background-color: #8c8f93 !important;
}

/* theme-bg-light-green */
.light-green,
.theme-text-light-green .theme-text-color,
.theme-text-light-green .theme-text-color a,
.theme-text-light-green.theme-text-color {
  color: #b2cc34 !important;
}
.bg-light-green,
.theme-bg-light-green .theme-bg-color,
.theme-bg-light-green.theme-bg-color,
.theme-bg-color .theme-bg-light-green .theme-bg-color,
.theme-text-light-green .theme-divider-color {
  background-color: #b2cc34 !important;
}
.theme-bg-light-green .focus-link a.theme-bg-color:hover,
.theme-bg-light-green .focus-link a.theme-bg-color:focus,
.focus-link.theme-bg-light-green a.theme-bg-color:hover,
.focus-link.theme-bg-light-green a.theme-bg-color:focus,
.theme-bg-color .focus-link.theme-bg-light-green a.theme-bg-color:hover,
.theme-bg-color .focus-link.theme-bg-light-green a.theme-bg-color:focus {
  background-color: #769231 !important;
}

/* theme-bg-medium-green */
.medium-green,
.theme-text-medium-green .theme-text-color,
.theme-text-medium-green .theme-text-color a,
.theme-text-medium-green.theme-text-color {
  color: #769231 !important;
}
.bg-medium-green,
.theme-bg-medium-green .theme-bg-color,
.theme-bg-medium-green.theme-bg-color,
.theme-bg-color .theme-bg-medium-green .theme-bg-color,
.theme-text-medium-green .theme-divider-color {
  background-color: #769231 !important;
}
.theme-bg-medium-green .focus-link a.theme-bg-color:hover,
.theme-bg-medium-green .focus-link a.theme-bg-color:focus,
.focus-link.theme-bg-medium-green a.theme-bg-color:hover,
.focus-link.theme-bg-medium-green a.theme-bg-color:focus,
.theme-bg-color .focus-link.theme-bg-medium-green a.theme-bg-color:hover,
.theme-bg-color .focus-link.theme-bg-medium-green a.theme-bg-color:focus {
  background-color: #444b0d !important;
}

/* theme-bg-medium-green */
.medium-green-stock {
  color: #3f841f !important;
}

/* theme-bg-dark-green */
.dark-green,
.theme-text-dark-green .theme-text-color,
.theme-text-dark-green .theme-text-color a,
.theme-text-dark-green.theme-text-color {
  color: #444b0d !important;
}
.bg-dark-green,
.theme-bg-dark-green .theme-bg-color,
.theme-bg-dark-green.theme-bg-color,
.theme-bg-color .theme-bg-dark-green .theme-bg-color,
.theme-text-dark-green .theme-divider-color {
  background-color: #444b0d !important;
}
.theme-bg-dark-green .focus-link a.theme-bg-color:hover,
.theme-bg-dark-green .focus-link a.theme-bg-color:focus,
.focus-link.theme-bg-dark-green a.theme-bg-color:hover,
.focus-link.theme-bg-dark-green a.theme-bg-color:focus,
.theme-bg-color .focus-link.theme-bg-dark-green a.theme-bg-color:hover,
.theme-bg-color .focus-link.theme-bg-dark-green a.theme-bg-color:focus {
  background-color: #769231 !important;
}

/* theme-bg-light-orange */
.light-orange,
.theme-text-light-orange .theme-text-color,
.theme-text-light-orange .theme-text-color a,
.theme-text-light-orange.theme-text-color {
  color: #faab18 !important;
}
.bg-light-orange,
.theme-bg-light-orange .theme-bg-color,
.theme-bg-light-orange.theme-bg-color,
.theme-bg-color .theme-bg-light-orange .theme-bg-color,
.theme-text-light-orange .theme-divider-color {
  background-color: #faab18 !important;
}
.theme-bg-light-orange .focus-link a.theme-bg-color:hover,
.theme-bg-light-orange .focus-link a.theme-bg-color:focus,
.focus-link.theme-bg-light-orange a.theme-bg-color:hover,
.focus-link.theme-bg-light-orange a.theme-bg-color:focus,
.theme-bg-color .focus-link.theme-bg-light-orange a.theme-bg-color:hover,
.theme-bg-color .focus-link.theme-bg-light-orange a.theme-bg-color:focus {
  background-color: #e5601f !important;
}

/* theme-bg-medium-orange */
.medium-orange,
.theme-text-medium-orange .theme-text-color,
.theme-text-medium-orange .theme-text-color a,
.theme-text-medium-orange.theme-text-color {
  color: #e5601f !important;
}
.bg-medium-orange,
.theme-bg-medium-orange .theme-bg-color,
.theme-bg-medium-orange.theme-bg-color,
.theme-bg-color .theme-bg-medium-orange .theme-bg-color,
.theme-text-medium-orange .theme-divider-color {
  background-color: #e5601f !important;
}
.theme-bg-medium-orange .focus-link a.theme-bg-color:hover,
.theme-bg-medium-orange .focus-link a.theme-bg-color:focus,
.focus-link.theme-bg-medium-orange a.theme-bg-color:hover,
.focus-link.theme-bg-medium-orange a.theme-bg-color:focus,
.theme-bg-color .focus-link.theme-bg-medium-orange a.theme-bg-color:hover,
.theme-bg-color .focus-link.theme-bg-medium-orange a.theme-bg-color:focus {
  background-color: #711b00 !important;
}

/* theme-bg-dark-orange */
.dark-orange,
.theme-text-dark-orange .theme-text-color,
.theme-text-dark-orange .theme-text-color a,
.theme-text-dark-orange.theme-text-color {
  color: #711b00 !important;
}
.bg-dark-orange,
.theme-bg-dark-orange .theme-bg-color,
.theme-bg-dark-orange.theme-bg-color,
.theme-bg-color .theme-bg-dark-orange .theme-bg-color,
.theme-text-dark-orange .theme-divider-color {
  background-color: #711b00 !important;
}
.theme-bg-dark-orange .focus-link a.theme-bg-color:hover,
.theme-bg-dark-orange .focus-link a.theme-bg-color:focus,
.focus-link.theme-bg-dark-orange a.theme-bg-color:hover,
.focus-link.theme-bg-dark-orange a.theme-bg-color:focus,
.theme-bg-color .focus-link.theme-bg-dark-orange a.theme-bg-color:hover,
.theme-bg-color .focus-link.theme-bg-dark-orange a.theme-bg-color:focus {
  background-color: #e5601f !important;
}

/* theme-bg-light-purple */
.light-purple,
.theme-text-light-purple .theme-text-color,
.theme-text-light-purple .theme-text-color a,
.theme-text-light-purple.theme-text-color {
  color: #ba3093 !important;
}
.bg-light-purple,
.theme-bg-light-purple .theme-bg-color,
.theme-bg-light-purple.theme-bg-color,
.theme-bg-color .theme-bg-light-purple .theme-bg-color,
.theme-text-light-purple .theme-divider-color {
  background-color: #ba3093 !important;
}
.theme-bg-light-purple .focus-link a.theme-bg-color:hover,
.theme-bg-light-purple .focus-link a.theme-bg-color:focus,
.focus-link.theme-bg-light-purple a.theme-bg-color:hover,
.focus-link.theme-bg-light-purple a.theme-bg-color:focus,
.theme-bg-color .focus-link.theme-bg-light-purple a.theme-bg-color:hover,
.theme-bg-color .focus-link.theme-bg-light-purple a.theme-bg-color:focus {
  background-color: #751269 !important;
}

/* theme-bg-medium-purple */
.medium-purple,
.theme-text-medium-purple .theme-text-color,
.theme-text-medium-purple .theme-text-color a,
.theme-text-medium-purple.theme-text-color {
  color: #751269 !important;
}
.bg-medium-purple,
.theme-bg-medium-purple .theme-bg-color,
.theme-bg-medium-purple.theme-bg-color,
.theme-bg-color .theme-bg-medium-purple .theme-bg-color,
.theme-text-medium-purple .theme-divider-color {
  background-color: #751269 !important;
}
.theme-bg-medium-purple .focus-link a.theme-bg-color:hover,
.theme-bg-medium-purple .focus-link a.theme-bg-color:focus,
.focus-link.theme-bg-medium-purple a.theme-bg-color:hover,
.focus-link.theme-bg-medium-purple a.theme-bg-color:focus,
.theme-bg-color .focus-link.theme-bg-medium-purple a.theme-bg-color:hover,
.theme-bg-color .focus-link.theme-bg-medium-purple a.theme-bg-color:focus {
  background-color: #3a0d36 !important;
}

/* theme-bg-dark-purple */
.dark-purple,
.theme-text-dark-purple .theme-text-color,
.theme-text-dark-purple .theme-text-color a,
.theme-text-dark-purple.theme-text-color {
  color: #3a0d36 !important;
}
.bg-dark-purple,
.theme-bg-dark-purple .theme-bg-color,
.theme-bg-dark-purple.theme-bg-color,
.theme-bg-color .theme-bg-dark-purple .theme-bg-color,
.theme-text-dark-purple .theme-divider-color {
  background-color: #3a0d36 !important;
}
.theme-bg-dark-purple .focus-link a.theme-bg-color:hover,
.theme-bg-dark-purple .focus-link a.theme-bg-color:focus,
.focus-link.theme-bg-dark-purple a.theme-bg-color:hover,
.focus-link.theme-bg-dark-purple a.theme-bg-color:focus,
.theme-bg-color .focus-link.theme-bg-dark-purple a.theme-bg-color:hover,
.theme-bg-color .focus-link.theme-bg-dark-purple a.theme-bg-color:focus {
  background-color: #751269 !important;
}

/* theme-bg-light-red */
.light-red,
.theme-text-light-red .theme-text-color,
.theme-text-light-red .theme-text-color a,
.theme-text-light-red.theme-text-color {
  color: #e21836 !important;
}
.bg-light-red,
.theme-bg-light-red .theme-bg-color,
.theme-bg-light-red.theme-bg-color,
.theme-bg-color .theme-bg-light-red .theme-bg-color,
.theme-text-light-red .theme-divider-color {
  background-color: #e21836 !important;
}
.theme-bg-light-red .focus-link a.theme-bg-color:hover,
.theme-bg-light-red .focus-link a.theme-bg-color:focus,
.focus-link.theme-bg-light-red a.theme-bg-color:hover,
.focus-link.theme-bg-light-red a.theme-bg-color:focus,
.theme-bg-color .focus-link.theme-bg-light-red a.theme-bg-color:hover,
.theme-bg-color .focus-link.theme-bg-light-red a.theme-bg-color:focus {
  background-color: #97002e !important;
}

/* theme-bg-medium-red */
.medium-red,
.theme-text-medium-red .theme-text-color,
.theme-text-medium-red .theme-text-color a,
.theme-text-medium-red.theme-text-color {
  color: #97002e !important;
}
.bg-medium-red,
.theme-bg-medium-red .theme-bg-color,
.theme-bg-medium-red.theme-bg-color,
.theme-bg-color .theme-bg-medium-red .theme-bg-color,
.theme-text-medium-red .theme-divider-color {
  background-color: #97002e !important;
}
.theme-bg-medium-red .focus-link a.theme-bg-color:hover,
.theme-bg-medium-red .focus-link a.theme-bg-color:focus,
.focus-link.theme-bg-medium-red a.theme-bg-color:hover,
.focus-link.theme-bg-medium-red a.theme-bg-color:focus,
.theme-bg-color .focus-link.theme-bg-medium-red a.theme-bg-color:hover,
.theme-bg-color .focus-link.theme-bg-medium-red a.theme-bg-color:focus {
  background-color: #58001c !important;
}

/* theme-bg-dark-red */
.dark-red,
.theme-text-dark-red .theme-text-color,
.theme-text-dark-red .theme-text-color a,
.theme-text-dark-red.theme-text-color {
  color: #58001c !important;
}
.bg-dark-red,
.theme-bg-dark-red .theme-bg-color,
.theme-bg-dark-red.theme-bg-color,
.theme-bg-color .theme-bg-dark-red .theme-bg-color,
.theme-text-dark-red .theme-divider-color {
  background-color: #58001c !important;
}
.theme-bg-dark-red .focus-link a.theme-bg-color:hover,
.theme-bg-dark-red .focus-link a.theme-bg-color:focus,
.focus-link.theme-bg-dark-red a.theme-bg-color:hover,
.focus-link.theme-bg-dark-red a.theme-bg-color:focus,
.theme-bg-color .focus-link.theme-bg-dark-red a.theme-bg-color:hover,
.theme-bg-color .focus-link.theme-bg-dark-red a.theme-bg-color:focus {
  background-color: #97002e !important;
}

/* theme-bg-light-teal */
.light-teal,
.theme-text-light-teal .theme-text-color,
.theme-text-light-teal .theme-text-color a,
.theme-text-light-teal.theme-text-color {
  color: #00b2bd !important;
}
.bg-light-teal,
.theme-bg-light-teal .theme-bg-color,
.theme-bg-light-teal.theme-bg-color,
.theme-bg-color .theme-bg-light-teal .theme-bg-color,
.theme-text-light-teal .theme-divider-color {
  background-color: #00b2bd !important;
}
.theme-bg-light-teal .focus-link a.theme-bg-color:hover,
.theme-bg-light-teal .focus-link a.theme-bg-color:focus,
.focus-link.theme-bg-light-teal a.theme-bg-color:hover,
.focus-link.theme-bg-light-teal a.theme-bg-color:focus,
.theme-bg-color .focus-link.theme-bg-light-teal a.theme-bg-color:hover,
.theme-bg-color .focus-link.theme-bg-light-teal a.theme-bg-color:focus {
  background-color: #00708c !important;
}

/* theme-bg-medium-teal */
.medium-teal,
.theme-text-medium-teal .theme-text-color,
.theme-text-medium-teal .theme-text-color a,
.theme-text-medium-teal.theme-text-color {
  color: #00708c !important;
}
.bg-medium-teal,
.theme-bg-medium-teal .theme-bg-color,
.theme-bg-medium-teal.theme-bg-color,
.theme-bg-color .theme-bg-medium-teal .theme-bg-color,
.theme-text-medium-teal .theme-divider-color {
  background-color: #00708c !important;
}
.theme-bg-medium-teal .focus-link a.theme-bg-color:hover,
.theme-bg-medium-teal .focus-link a.theme-bg-color:focus,
.focus-link.theme-bg-medium-teal a.theme-bg-color:hover,
.focus-link.theme-bg-medium-teal a.theme-bg-color:focus,
.theme-bg-color .focus-link.theme-bg-medium-teal a.theme-bg-color:hover,
.theme-bg-color .focus-link.theme-bg-medium-teal a.theme-bg-color:focus {
  background-color: #003653 !important;
}

/* theme-bg-dark-teal */
.dark-teal,
.theme-text-dark-teal .theme-text-color,
.theme-text-dark-teal .theme-text-color a,
.theme-text-dark-teal.theme-text-color {
  color: #003653 !important;
}
.bg-dark-teal,
.theme-bg-dark-teal .theme-bg-color,
.theme-bg-dark-teal.theme-bg-color,
.theme-bg-color .theme-bg-dark-teal .theme-bg-color,
.theme-text-dark-teal .theme-divider-color {
  background-color: #003653 !important;
}
.theme-bg-dark-teal .focus-link a.theme-bg-color:hover,
.theme-bg-dark-teal .focus-link a.theme-bg-color:focus,
.focus-link.theme-bg-dark-teal a.theme-bg-color:hover,
.focus-link.theme-bg-dark-teal a.theme-bg-color:focus,
.theme-bg-color .focus-link.theme-bg-dark-teal a.theme-bg-color:hover,
.theme-bg-color .focus-link.theme-bg-dark-teal a.theme-bg-color:focus {
  background-color: #00708c !important;
}

/* theme-bg-white */
.white,
.theme-text-white .theme-text-color,
.theme-text-white .theme-text-color a,
.theme-text-white.theme-text-color {
  color: #ffffff !important;
}
.bg-white,
.theme-bg-white .theme-bg-color,
.theme-bg-white.theme-bg-color,
.theme-bg-color .theme-bg-white .theme-bg-color,
.theme-text-white .theme-divider-color {
  background-color: #ffffff !important;
}
.theme-bg-white .focus-link a.theme-bg-color:hover,
.theme-bg-white .focus-link a.theme-bg-color:focus,
.focus-link.theme-bg-white a.theme-bg-color:hover,
.focus-link.theme-bg-white a.theme-bg-color:focus,
.theme-bg-color .focus-link.theme-bg-white a.theme-bg-color:hover,
.theme-bg-color .focus-link.theme-bg-white a.theme-bg-color:focus {
  background-color: #dbdcdd !important;
}

/* theme-bg-offwhite */
.offwhite,
.theme-text-offwhite .theme-text-color,
.theme-text-offwhite.theme-text-color {
  color: #f4f4f4 !important;
}
.bg-offwhite,
.theme-bg-offwhite .theme-bg-color,
.theme-bg-offwhite.theme-bg-color,
.theme-bg-color .theme-bg-offwhite .theme-bg-color,
.theme-text-offwhite .theme-divider-color {
  background-color: #f4f4f4 !important;
}
.theme-bg-offwhite .focus-link a.theme-bg-color:hover,
.theme-bg-offwhite .focus-link a.theme-bg-color:focus,
.focus-link.theme-bg-offwhite a.theme-bg-color:hover,
.focus-link.theme-bg-offwhite a.theme-bg-color:focus,
.theme-bg-color .focus-link.theme-bg-offwhite a.theme-bg-color:hover,
.theme-bg-color .focus-link.theme-bg-offwhite a.theme-bg-color:focus {
  background-color: #dbdcdd !important;
}

/*Color fix for backgrounds added to non .focus-link .arrow-link*/
:not(.focus-link) > .arrow-link.theme-bg-color,
:not(.focus-link) > .arrow-link.theme-bg-color:hover {
  background-color: transparent !important;
}

/* End - colors */

/* General Text Handling Classes - helper classes */
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-italic {
  font-style: italic !important;
}
.text-bold {
  font-weight: bold !important;
}
.text-normal {
  font-weight: normal !important;
}

.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}

.position-left {
  position: absolute;
  left: 0;
}
.position-top {
  position: absolute;
  top: 0;
}
.position-right {
  position: absolute;
  right: 0;
}
.position-bottom {
  position: absolute;
  bottom: 0;
}

/* General Padding (spacing) Helper Classes */
.padding-0 {
  padding: 0 !important;
}
.padding-top-0 {
  padding-top: 0 !important;
}
.padding-right-0 {
  padding-right: 0 !important;
}
.padding-bottom-0 {
  padding-bottom: 0 !important;
}
.padding-left-0 {
  padding-left: 0 !important;
}
.padding-5 {
  padding: 5px !important;
}
.padding-top-5 {
  padding-top: 5px !important;
}
.padding-right-5 {
  padding-right: 5px !important;
}
.padding-bottom-5 {
  padding-bottom: 5px !important;
}
.padding-left-5 {
  padding-left: 5px !important;
}
.padding-10 {
  padding: 10px !important;
}
.padding-top-10 {
  padding-top: 10px !important;
}
.padding-right-10 {
  padding-right: 10px !important;
}
.padding-bottom-10 {
  padding-bottom: 10px !important;
}
.padding-left-10 {
  padding-left: 10px !important;
}
.padding-15 {
  padding: 15px !important;
}
.padding-top-15 {
  padding-top: 15px !important;
}
.padding-right-15 {
  padding-right: 15px !important;
}
.padding-bottom-15 {
  padding-bottom: 15px !important;
}
.padding-left-15 {
  padding-left: 15px !important;
}
.padding-20 {
  padding: 20px !important;
}
.padding-top-20 {
  padding-top: 20px !important;
}
.padding-right-20 {
  padding-right: 20px !important;
}
.padding-bottom-20 {
  padding-bottom: 20px !important;
}
.padding-left-20 {
  padding-left: 20px !important;
}
.padding-25 {
  padding: 25px !important;
}
.padding-top-25 {
  padding-top: 25px !important;
}
.padding-right-25 {
  padding-right: 25px !important;
}
.padding-bottom-25 {
  padding-bottom: 25px !important;
}
.padding-left-25 {
  padding-left: 25px !important;
}
.padding-30 {
  padding: 30px !important;
}
.padding-top-30 {
  padding-top: 30px !important;
}
.padding-right-30 {
  padding-right: 30px !important;
}
.padding-bottom-30 {
  padding-bottom: 30px !important;
}
.padding-left-30 {
  padding-left: 30px !important;
}
.padding-35 {
  padding: 35px !important;
}
.padding-top-35 {
  padding-top: 35px !important;
}
.padding-right-35 {
  padding-right: 35px !important;
}
.padding-bottom-35 {
  padding-bottom: 35px !important;
}
.padding-left-35 {
  padding-left: 35px !important;
}
.padding-40 {
  padding: 40px !important;
}
.padding-top-40 {
  padding-top: 40px !important;
}
.padding-right-40 {
  padding-right: 40px !important;
}
.padding-bottom-40 {
  padding-bottom: 40px !important;
}
.padding-left-40 {
  padding-left: 40px !important;
}

/* General Margin (spacing) Helper Classes */
.margin-0 {
  margin: 0 !important;
}
.margin-top-0 {
  margin-top: 0 !important;
}
.margin-right-0 {
  margin-right: 0 !important;
}
.margin-bottom-0 {
  margin-bottom: 0 !important;
}
.margin-left-0 {
  margin-left: 0 !important;
}
.margin-5 {
  margin: 5px !important;
}
.margin-top-5 {
  margin-top: 5px !important;
}
.margin-right-5 {
  margin-right: 5px !important;
}
.margin-bottom-5 {
  margin-bottom: 5px !important;
}
.margin-left-5 {
  margin-left: 5px !important;
}
.margin-10 {
  margin: 10px !important;
}
.margin-top-10 {
  margin-top: 10px !important;
}
.margin-right-10 {
  margin-right: 10px !important;
}
.margin-bottom-10 {
  margin-bottom: 10px !important;
}
.margin-left-10 {
  margin-left: 10px !important;
}
.margin-15 {
  margin: 15px !important;
}
.margin-top-15 {
  margin-top: 15px !important;
}
.margin-right-15 {
  margin-right: 15px !important;
}
.margin-bottom-15 {
  margin-bottom: 15px !important;
}
.margin-left-15 {
  margin-left: 15px !important;
}
.margin-20 {
  margin: 20px !important;
}
.margin-top-20 {
  margin-top: 20px !important;
}
.margin-right-20 {
  margin-right: 20px !important;
}
.margin-bottom-20 {
  margin-bottom: 20px !important;
}
.margin-left-20 {
  margin-left: 20px !important;
}
.margin-25 {
  margin: 25px !important;
}
.margin-top-25 {
  margin-top: 25px !important;
}
.margin-right-25 {
  margin-right: 25px !important;
}
.margin-bottom-25 {
  margin-bottom: 25px !important;
}
.margin-left-25 {
  margin-left: 25px !important;
}
.margin-30 {
  margin: 30px !important;
}
.margin-top-30 {
  margin-top: 30px !important;
}
.margin-right-30 {
  margin-right: 30px !important;
}
.margin-bottom-30 {
  margin-bottom: 30px !important;
}
.margin-left-30 {
  margin-left: 30px !important;
}
.margin-35 {
  margin: 35px !important;
}
.margin-top-35 {
  margin-top: 35px !important;
}
.margin-right-35 {
  margin-right: 35px !important;
}
.margin-bottom-35 {
  margin-bottom: 35px !important;
}
.margin-left-35 {
  margin-left: 35px !important;
}
.margin-40 {
  margin: 40px !important;
}
.margin-top-40 {
  margin-top: 40px !important;
}
.margin-right-40 {
  margin-right: 40px !important;
}
.margin-bottom-40 {
  margin-bottom: 40px !important;
}
.margin-left-40 {
  margin-left: 40px !important;
}

/* print styles */
@media print {
  /*unviversal*/
  * {
    -webkit-print-color-adjust: exact;
  }

  *,
  *:before,
  *:after {
    color: inherit !important;
  }

  body {
    width: 100%;
    margin: 0;
    float: none;
    /*-webkit-print-color-adjust: exact;*/
  }
  body,
  main#main-content,
  footer {
    background-color: transparent !important;
  }
  a[href]:after {
    content: none !important;
  }

  /* email */
  .Skin button.btn.focus-link {
    display: inline-block !important;
  }

  /* page break fixes *,*/
  :after,
  :before,
  .image,
  .images,
  .img-responsive,
  div.map,
  div.did-you-know .embed-responsive,
  div.focus-link,
  .text-box-container,
  .carousel-common .carousel-inner .item,
  .carousel-common .col,
  .carousel-a .text-col,
  .carousel-a .carousel-inner,
  .carousel-social .carousel-inner .social-tile,
  .slick-track .item,
  .footer .row.main,
  .footer.bg-offwhite,
  .promo .background-half,
  .promo-tiles .promo .img-responsive.centered,
  .resources-container,
  .footer.bg-offwhite {
    page-break-inside: avoid;
  }

  /*Hide CMS Ribbon*/
  #scCrossPiece,
  #scPageExtendersForm {
    display: none;
  }

  /* focus link */
  .focus-link {
    width: auto !important;
  }
  .focus-link a {
    color: inherit !important;
    margin: auto !important;
    width: auto !important;
    max-width: 350px !important;
  }
  .focus-link .arrow-link span.glyphicon {
    top: 0px !important;
    display: inline-block !important;
  }
  .arrow-link .glyphicon {
    color: inherit !important;
  }
  .flip-card .card-bottom .arrow-link {
    color: inherit !important;
  }

  /* back to top */
  .back-to-top {
    visibility: hidden;
  }

  /* tabs */
  .tab-content > .tab-pane {
    display: block !important;
  }

  .home-bottom-nav.multi-col .col h4 {
    margin-left: 0px !important;
  }
  .home-bottom-nav span.glyphicon {
    display: inline-block !important;
  }

  /* Timeline */
  /* print styles for slick */
  .slider.slider-nav {
    display: none !important;
  }
  .slick-track {
    width: auto !important;
    transform: translate3d(0, 0, 0) !important;
  }
  .slick-track .item {
    margin-bottom: 10px !important;
    float: none !important;
  }
  .timeline .slick-track .slick-slide.item {
    height: auto !important;
    margin: auto !important;
    width: 500px !important;
    margin-bottom: 10px !important;
  }
  .timeline .slick-slide img {
    display: none !important;
  }
  .timeline .tile-right {
    background-color: none !important;
    display: none !important;
  }
  .timeline .slick-slide .col {
    width: 100% !important;
  }
  .timeline-heavy .slick-track {
    width: initial !important;
    transform: initial !important;
  }

  .num-container,
  span.slide-count {
    display: none !important;
  }

  /*May have Limited browser support.*/
  @page {
    size: portrait;
  }
}
@media print and (min-width: 768px) {
  .media-container h4 {
    margin-top: 30px !important;
    margin-bottom: 0px !important;
    text-align: left !important;
    padding-bottom: 0px !important;
    display: block !important;
    float: none !important;
    line-height: none !important;
    height: 30px !important;
  }
}
@media print and (min-width: 1280px) {
  .timeline .container {
    width: 600px !important;
    max-width: none !important;
  }
}
/* IE 10/11 Print Hack */
@media print and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .home.section-b .home-cards {
    margin-top: 30px !important;
  }
  .content-basics.intro-copy.container-fluid.width-1280 {
    display: block !important;
    float: none !important;
    position: static !important;
  }
  .search-bar label.placeholder,
  .search label.placeholder {
    display: none !important;
  }
}
.nav > li.menu-hover > a {
  background-color: transparent;
}
.nav > li.menu-hover > a:hover {
  background-color: transparent;
}

/****************** Components ******************/
.w2 {
  width: 2%;
}
.w4 {
  width: 4%;
}
.w5 {
  width: 5%;
}
.w6 {
  width: 6%;
}
.w8 {
  width: 8%;
}
.w10 {
  width: 10%;
}
.w12 {
  width: 12%;
}
.w14 {
  width: 14%;
}
.w15 {
  width: 15%;
}
.w16 {
  width: 16%;
}
.w17 {
  width: 17%;
}
.w18 {
  width: 18%;
}
.w20 {
  width: 20%;
}
.w21 {
  width: 21%;
}
.w22 {
  width: 22%;
}
.w23 {
  width: 23%;
}
.w30 {
  width: 30%;
}
.w31 {
  width: 31%;
}
.w40 {
  width: 40%;
}
.w50 {
  width: 50%;
}
.w64 {
  width: 64%;
}
.w65 {
  width: 65%;
}
.w70 {
  width: 70%;
}
.w72 {
  width: 72%;
}
.w73 {
  width: 73%;
}
.w76 {
  width: 76%;
}
.w80 {
  width: 80%;
}
.w100 {
  width: 100%;
}

.mr6 {
  margin-right: 6px;
}
.mr12 {
  margin-right: 12px;
}
.mr14 {
  margin-right: 14px;
}
.ml4 {
  margin-left: 4px;
}
.ml6 {
  margin-left: 6px;
}
.ml8 {
  margin-left: 8px;
}
.ml12 {
  margin-left: 12px;
}
.ml10 {
  margin-left: 10px;
}
.ml15 {
  margin-left: 15px;
}
.ml20 {
  margin-left: 20px;
}
.ml104 {
  margin-left: 104px;
}
.ml-78i {
  margin-left: -78px !important;
}
.mb0 {
  margin-bottom: 0px;
}
.mb4 {
  margin-bottom: 4px;
}
.mb6 {
  margin-bottom: 6px;
}
.mb8 {
  margin-bottom: 8px;
}
.mb10 {
  margin-bottom: 10px;
}
.mt8 {
  margin-top: 8px;
}
.mt12 {
  margin-top: 12px;
}
.mt24 {
  margin-top: 24px;
}

.pl0 {
  padding-left: 0px !important;
}
.pl8 {
  padding-left: 8px;
}

.w100i {
  width: 100px !important;
}

.fr {
  float: right;
}
.fl {
  float: left;
}
.tac {
  text-align: center;
}
.noPadding {
  padding: 0;
}
.noMarginBottom {
  margin-bottom: 0 !important;
}

.headerSection {
  border-left: 1px solid #e6e6e6;
}
.noPaddingLeft {
  padding-left: 0;
}
.noPaddingRight {
  padding-right: 0;
}
.divCenter {
  display: flex;
  align-items: center;
}
.divCenter40 {
  height: 40px;
  display: flex;
  align-items: center;
}
.divCenter2 {
  height: 32px;
  display: flex;
  align-items: center;
}
.centerContent {
  display: flex;
  align-items: center;
}
.cursor {
  cursor: pointer;
}
.helpCursor {
  cursor: help;
}

.fs18 {
  font-size: 18px;
}
.emailHelp {
  color: #2e7d32;
  font-size: 18px;
}
.subTitle {
  color: #0066b2;
  font-size: 14px;
  font-weight: bold;
  font-family: 'GothamNarrowBook', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.rigName {
  color: #2e7d32;
  font-size: 18px;
  font-weight: normal;
  font-family: 'GothamBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.appName {
  color: #009dd9;
  font-size: 18px;
  font-weight: normal;
  font-family: 'GothamBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.titleCreateNew {
  color: #0b2d71;
  font-size: 24px;
  font-weight: bold;
  font-family: 'GothamNarrowBook', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.rigTime {
  font-size: 14px;
  color: #000000;
  font-weight: bold;
  font-family: 'GothamNarrowBook', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.normalText {
  font-weight: normal;
  font-family: 'GothamNarrowBook', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.boldTextFont {
  font-weight: bold;
  font-family: 'GothamNarrowBook', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.errorSpan {
  font-size: 14px;
  color: #710b04;
  font-style: italic;
}
.edtIcon {
  font-size: 24px;
  color: #0066b2;
  cursor: pointer;
}
.dltIcon {
  font-size: 24px;
  color: #bf2216;
  cursor: pointer;
}
.centerNoData {
  font-size: 14px;
  text-align: center !important;
  width: 100%;
}
.threadLoss {
  padding-right: 0px;
  padding-left: 0px;
}
.threadLossCheck {
  padding-right: 0px;
  padding-left: 2px;
  margin-top: 7px;
  margin-left: 0px;
}

textarea {
  resize: none;
  font-weight: bold;
  font-family: 'GothamNarrowBook', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  outline: none !important;
}
button {
  font-weight: normal;
  font-family: 'GothamNarrowBook', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
caption {
  color: black;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}
span {
  font-size: 14px;
  font-weight: normal;
  font-family: 'GothamNarrowBook', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.mark-asterisk {
  font-size: 18px;
  color: #710b04;
  font-weight: bold;
}

/* Prime NG */
.ui-datepicker .ui-datepicker-group {
  line-height: 6px;
}

/* Loading */
.loaderCenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 11%;
  margin-top: 20%;
}
.loadText {
  margin-top: 2%;
  width: 50%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.loadText span {
  font-size: 18px;
}
.loadTextApolo {
  margin-top: 4%;
  text-align: center;
}
.loadTextApolo span {
  font-size: 20px;
}
.loader {
  border: 12px solid #f3f3f3;
  border-radius: 50%;
  border-top: 12px solid #0b2d71;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
