.tabbed-content-control .nav-tabs {
  text-align: center;
  border-bottom: 0;
}
.tabbed-content-control .nav-tabs > li {
  float: none;
  display: inline-block;
  color: #6b6d6f;
  margin: 0 15px;
  padding-left: 0;
}
.tabbed-content-control .nav-tabs > li.active {
  color: black;
}
.tabbed-content-control .nav-tabs > li > a {
  border: 0;
  padding: 0;
  color: inherit;
  background-color: transparent;
}
.tabbed-content-control .nav-tabs > li.active > a,
.tabbed-content-control .nav-tabs > li.active > a:hover,
.tabbed-content-control .nav-tabs > li > a:hover,
.tabbed-content-control .nav-tabs > li.active > a:focus {
  color: black;
  border: 0;
  border-bottom: 4px solid black;
  cursor: pointer;
  padding: 0;
  display: inline;
}
.tabbed-content-control .tab-content {
  padding-top: 15px;
}

@media (max-width: 768px) {
  .tabbed-content-control .nav-tabs {
    border-top: 4px solid lightgray;
    font-size: 0.8em;
    padding-top: 20px;
  }
  .tabbed-content-control .nav-tabs > li {
    display: block;
    color: black;
    text-align: left;
    margin: 0;
    padding: 10px;
  }
  .tabbed-content-control .nav-tabs > li:hover {
    background-color: lightgray;
  }
  .tabbed-content-control .nav-tabs > li > a:hover {
    border-bottom: 0;
    padding-left: 20px;
  }
  .tabbed-content-control .nav-tabs > li.active > a,
  .tabbed-content-control .nav-tabs > li.active > a:hover,
  .tabbed-content-control .nav-tabs > li.active > a:focus {
    border-bottom: 0;
    background-color: transparent;
    padding-left: 20px;
  }
  .tabbed-content-control .nav-tabs > li:before {
    content: '\e603';
    top: 12px;
    padding-left: 12px;
  }
  .tabbed-content-control .nav-tabs > li > a {
    padding-left: 20px;
  }
}
