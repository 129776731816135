footer {
  background: #ededee;
  padding: 40px 20px;
  font-size: 14px;
  text-align: center;
}
footer .navbar-brand {
  height: 100%;
}
footer .list-inline {
  margin-left: 0;
  margin-bottom: 20px;
}
footer .list-inline:last-child {
  margin-bottom: 0;
}
footer ul li {
  list-style-type: none;
}
footer a {
  font-weight: 900;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  footer .list-inline > li {
    border-right: 1px solid #dbdcdd;
    padding-right: 15px;
    padding-left: 12px;
    line-height: 1.5em;
  }
  footer .list-inline > li:last-child {
    border-right: none;
  }
}

/* Extra Small and below */
@media (max-width: 767px) {
  footer {
    text-align: center;
  }
  footer .navbar-brand {
    float: none;
    height: 100%;
    padding-bottom: 0;
  }
  footer .list-inline {
    margin-bottom: 0;
  }
  footer .list-inline > li {
    display: block;
    padding-right: 0;
    padding-left: 0;
  }
}
