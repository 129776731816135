@font-face {
  font-family: 'ChevronIcons';
  src: url('../../fonts/ChevronIcons.eot?5ul0xy');
  src: url('../../fonts/ChevronIcons.eot?#iefix5ul0xy') format('embedded-opentype'),
    url('../../fonts/ChevronIcons.ttf?5ul0xy') format('truetype'),
    url('../../fonts/ChevronIcons.woff?5ul0xy') format('woff'),
    url('../../fonts/ChevronIcons.svg?5ul0xy#ChevronIcons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='glyphicon-'],
[class*=' glyphicon-'] {
  font-family: 'ChevronIcons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon-bootstrap {
  font-family: 'Glyphicons Halflings';
}

.glyphicon-arrow-up:before {
  content: '\e5fd';
}
.glyphicon-arrow-down:before {
  content: '\e5fe';
}
.glyphicon-arrow-left:before {
  content: '\e5ff';
}
.glyphicon-arrow-right:before {
  content: '\e600';
}
.glyphicon-chevron-down:before {
  content: '\e601';
}
.glyphicon-chevron-left:before {
  content: '\e602';
}
.glyphicon-chevron-right {
  font-family: 'GothamBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.glyphicon-chevron-right:before {
  content: '\203a';
  font-size: 2.1em;
  position: relative;
  line-height: 0.5em;
}
.glyphicon-chevron-up:before {
  content: '\e604';
}
.glyphicon-credit-card:before {
  content: '\e605';
}
.glyphicon-file:before {
  content: '\e606';
}
.glyphicon-globe:before {
  content: '\e607';
}
.glyphicon-menu-hamburger:before {
  content: '\e608';
}
.glyphicon-menu-left:before {
  content: '\e609';
}
.glyphicon-menu-right:before {
  content: '\e60a';
}
.glyphicon-new-window:before {
  content: '\e60b';
}
.glyphicon-oil:before {
  content: '\e60c';
}
.glyphicon-play-circle:before {
  content: '\e60d';
}
.glyphicon-remove:before {
  content: '\e60e';
}
.glyphicon-search:before {
  content: '\e60f';
}
.glyphicon-share-alt:before {
  content: '\e610';
}
.glyphicon-stats:before {
  content: '\e611';
}
.glyphicon-tag:before {
  content: '\e612';
}
.glyphicon-th-list:before {
  content: '\e613';
}
.glyphicon-th:before {
  content: '\e614';
}
.glyphicon-user:before {
  content: '\e615';
}
.glyphicon-media:before {
  content: '\e616';
}
.glyphicon-comment:before {
  content: '\e618';
}
.glyphicon-thumbsup:before {
  content: '\e619';
}
.glyphicon-downarrow:before {
  content: '\e620';
}
.glyphicon-uparrow:before {
  content: '\e621';
}
.glyphicon-chaos:before {
  content: '\e622';
}
.glyphicon-insidehome:before {
  content: '\e623';
}
.glyphicon-peoplefinder:before {
  content: '\e624';
}

/*note: this icon uses default bootstrap font */
.glyphicon-download:before {
  content: '\e026';
  font-family: 'Glyphicons Halflings';
}

/*note: this icon uses default bootstrap font */
.glyphicon-download-alt:before {
  content: '\e025';
  font-family: 'Glyphicons Halflings';
}

/*note: this icon uses default bootstrap font */
.glyphicon-home:before {
  content: '\e021';
  font-family: 'Glyphicons Halflings';
  font-size: 0.87em;
}
/* glyphicon layouts for components > glyphicons partial */
#glyphs {
  padding-bottom: 5%;
}
.glyph-ul {
  display: block;
  height: auto;
  padding-left: 0px !important;
  -webkit-padding-start: 0px !important;
  word-break: keep-all;
  margin-bottom: 50px;
}
.glyph-ul .glyph-li {
  list-style-type: none;
  padding: 3% 1%;
  border: 1px solid #dddbdc;
}
.glyph-ul .glyph-li:hover {
  color: #dddbdc;
  background-color: #0b2d71 !important;
  transition: background 0.2s linear;
}
.glyph-ul .glyph-li-icon {
  font-size: 1.2em !important;
  line-height: 1.35em;
  font-weight: 900;
}
.glyph-ul .glyph-li .glyphicon-chevron-right:before {
  line-height: 0.1em !important;
}
.glyph-ul .glyph-li-iconlabel {
  display: block;
  min-height: 3.2em;
  max-height: 3.2em;
  font-size: 0.85em;
  line-height: 1.15em;
  font-weight: 400;
  text-align: center;
  word-wrap: break-word;
}

/* glyphicon adjustments for mobile max-width:380px > re: components > glyphicon partial */
@media screen and (max-width: 380px) {
  .glyph-ul .glyph-li-icon {
    font-size: 1em !important;
    line-height: 1.1em;
    font-weight: 900;
  }
  .glyph-ul .glyph-li .glyphicon-chevron-right:before {
    line-height: 0.08em !important;
  }
  .glyph-ul .glyph-li-iconlabel {
    font-size: 0.73em;
    line-height: 1.15em;
  }
}
/* glyphicon adjustments for mobile at screen ratio of 40:71 > re: components > glyphicon partial */
@media screen and (device-aspect-ratio: 40/71) {
  .glyph-ul .glyph-li-icon {
    font-size: 0.9em !important;
    line-height: 1em;
    font-weight: 900;
  }
  .glyph-ul .glyph-li .glyphicon-chevron-right:before {
    line-height: 0.08em !important;
  }
  .glyph-ul .glyph-li-iconlabel {
    font-size: 0.7em;
    line-height: 1em;
  }
}
/* component code styles */
.glyph-bnb,
.tabbed-bnb {
  border: solid 1px #bfbfbf;
  background-color: white;
}
