/* All devices*/
.home .left-column .module h3 {
  padding-top: 5px;
  margin-bottom: 5px;
}
.home .op-company-promo-module > h2,
.home .industry-news-module > h2 {
  padding-top: 0;
}
.home .main-column .module {
  padding-bottom: 20px;
}
.home .svc-soc-list li {
  padding-left: 2px !important;
}

@media (min-width: 768px) {
  .home main#main-content {
    padding-top: 0;
  }
  .home .left-column {
    padding-right: 29px;
  }
  .home .company-promo-module,
  .home .op-company-promo-module,
  .home .social-module {
    padding-left: 8px;
    padding-right: 8px;
  }
}
