.sub-page .main-column,
.sub-page .right-column {
  padding: 0;
}

.sub-page .main-column ul li > ul {
  margin-top: 10px;
}

.sub-page .main-column .main-hero,
.sub-page .main-column .main-content {
  padding: 0 10px;
}

.sub-page .main-column .sub-hero {
  margin-bottom: 40px;
}
.sub-page .main-column .main-hero .text-content,
.sub-page .main-column .sub-hero .text-content,
.sub-page .main-column .main-content .sub-content {
  padding: 0 13px;
}
.sub-page .main-column .main-hero .text-content p,
.sub-page .main-column .sub-hero .text-content p {
  text-align: center;
  font-family: 'GothamBookMedium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.sub-page .main-column .main-hero img,
.sub-page .main-column .sub-hero img {
  width: 100%;
  height: auto;
}

/* Begin icon-list*/
.sub-page .main-column ul:not(.content-list):not(.thumblist):not(.tabs),
.sub-page .right-column ul:not(.content-list):not(.thumblist):not(.tabs) {
  padding-left: 0;
}
.sub-page .main-column .module ul:not(.content-list):not(.thumblist):not(.tabs),
.sub-page .right-column .module ul:not(.content-list):not(.thumblist):not(.tabs) {
  list-style-type: none;
}
.sub-page .main-column ul:not(.content-list):not(.thumblist):not(.tabs) li,
.sub-page .right-column ul:not(.content-list):not(.thumblist):not(.tabs) li {
  list-style-type: none;
  padding: 0 0 5px 16px;
  display: block;
  position: relative;
  line-height: 1.31em;
  margin-bottom: 0.4em;
}
.sub-page .main-column ul:not(.content-list):not(.thumblist):not(.tabs) li:before,
.sub-page .right-column ul:not(.content-list):not(.thumblist):not(.tabs) li:before {
  font-family: 'GothamBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  content: '\203a';
  position: absolute;
  left: 0;
  top: -1px;
  color: #0066b2;
}

.sub-page .main-column ul:not(.content-list):not(.thumblist):not(.tabs) li a,
.sub-page .right-column ul:not(.content-list):not(.thumblist):not(.tabs) li a {
  font-family: 'GothamBookMedium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.sub-page .main-column ul:not(.content-list):not(.thumblist):not(.tabs) li a + a,
  .sub-page .right-column ul:not(.content-list):not(.thumblist):not(.tabs) li a + a  {
  font-family: 'GothamBook', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
/* End icon-list*/
.sub-page .right-column .module h3 {
  padding-top: 5px;
  margin-bottom: 10px;
  color: #0b2d71;
}

.sub-page .right-column .module:not(.col) {
  float: left;
  width: 100%;
  -webkit-box-shadow: 2px 2px 0 0 #dbdcdd;
  -moz-box-shadow: 2px 2px 0 0 #dbdcdd;
  box-shadow: 2px 2px 0 0 #dbdcdd;
}
.sub-page .main-column .news-feed {
  margin-bottom: 25px;
}
.sub-page .news-feed p.news-item,
.sub-page .news-item-container {
  margin-bottom: 0;
}
.sub-page .main-column .feature img {
  width: auto;
  text-align: center;
  margin: 0 auto;
  display: block;
}

/* Small, Med, and Large devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .sub-page .nav-column {
    padding-right: 29px;
  }
  .sub-page .main-column .main-hero,
  .sub-page .main-column .main-content {
    padding: 0 18px;
  }
  .sub-page .main-column .main-hero .text-content,
  .sub-page .main-column .sub-hero .text-content,
  .sub-page .main-column .main-content .sub-content {
    padding: 0 22px;
  }
  .sub-page .main-column .feature {
    margin-bottom: 20px;
  }
  .sub-page .main-column .feature:before {
    display: table;
    content: ' ';
  }
  .sub-page .main-column .feature:after {
    clear: both;
    display: table;
    content: ' ';
  }
  .sub-page .main-column .feature .feature-image,
  .sub-page .main-column .feature img {
    float: left;
    width: 44%;
    height: auto;
  }
  .sub-page .main-column .feature .feature-image img {
    float: none;
  }
  .sub-page .main-column .feature .no-wrap {
    float: left;
    width: 56%;
    padding-left: 20px;
  }
  .sub-page .main-column .feature h1,
  .sub-page .main-column .feature h2,
  .sub-page .main-column .feature h3,
  .sub-page .main-column .feature h4,
  .sub-page .main-column .feature h5,
  .sub-page .main-column .feature h6,
  .sub-page .main-column .feature p {
    margin-top: 0;
    margin-bottom: 10px;
    padding-top: 0;
    text-align: left;
    font-size: 1em;
  }
  .sub-page .main-column .main-content .main-content-image {
    padding: 0 20px;
  }
}
@media (min-width: 992px) {
  .sub-page .main-column {
    padding-right: 29px;
  }
}
