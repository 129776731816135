body {
  .ui-dropdown {
    border: 1px solid #d6d6d6;

    &:not(.ui-state-disabled):hover {
      background-color: #d9d9d9;
      border-color: #cccccc;

      .ui-dropdown-trigger,
      .ui-dropdown-clear-icon {
        background-color: #d9d9d9;
      }
    }

    .ui-dropdown-label {
      background: #ffffff;
      padding-right: 3em;
      text-overflow: ellipsis;
    }

    .ui-dropdown-trigger,
    .ui-dropdown-clear-icon {
      background-color: #ffffff;
    }

    .ui-dropdown-trigger .fa {
      color: #55595c;
      margin-top: 0.65em;
    }

    &:not(.ui-state-disabled):hover .ui-dropdown-label,
    &:not(.ui-state-disabled):hover .ui-dropdown-trigger,
    &:not(.ui-state-disabled):hover .ui-dropdown-clear-icon,
    &.ui-state-focus .ui-dropdown-label,
    &.ui-state-focus .ui-dropdown-trigger,
    &.ui-state-focus .ui-dropdown-clear-icon {
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      -moz-border-top-left-radius: 0;
      -webkit-border-top-left-radius: 0;
      border-top-left-radius: 0;
      -moz-border-bottom-left-radius: 0;
      -webkit-border-bottom-left-radius: 0;
      border-bottom-left-radius: 0;
      background-color: #d9d9d9;
    }

    &.ui-state-focus .ui-dropdown-label.ui-inputtext {
      border: 0 none;
    }
  }

  .ui-dropdown-panel {
    .ui-dropdown-filter-container {
      padding: 0.375em 0.75em;

      .ui-dropdown-filter {
        width: 100%;
        box-sizing: border-box;
        padding-right: 1.25em;
      }

      .fa {
        top: 1.05em;
        right: 1.25em;
      }
    }

    .ui-dropdown-items {
      padding: 0;

      .ui-dropdown-item {
        padding: 0.375em 0.75em;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0;
      }

      .ui-dropdown-item:not(.ui-state-highlight):hover {
        background-color: #f4f3f4;
        color: #373a3c;
      }

      .ui-dropdown-item.ui-state-highlight {
        background-color: #0275d8;
        color: #ffffff;
      }

      .ui-dropdown-item > td {
        padding: 0.375em 0.75em;
      }

      .ui-dropdown-item-group {
        padding: 0.375em 0.75em;
        background-color: #e6e6e6;
      }
    }
  }
}
