.grid-control-200 {
  width: 180px;
  padding: 0;
  margin: 0;
  height: 19px;
}

.grid-control-250 {
  width: 230px;
  padding: 0;
  margin: 0;
  height: 30px;
  margin-right: 10px;
}

.grid-control-400 {
  width: 360px;
  padding: 0;
  margin: 0;
  height: 19px;
}

.grid-col-200 {
  width: 200px;
  padding: 0;
  margin: 0;
}

.grid-col-400 {
  width: 400px;
  padding: 0;
  margin: 0;
}

.grid-control-100 {
  width: 80px;
  padding: 0;
  margin: 0;
  height: 19px;
}

.grid-col-100 {
  width: 100px;
  padding: 0;
  margin: 0;
}

.grid-label {
  font-size: 13px;
  text-align: left;
  font-weight: normal;
}

input[type='text']::-ms-clear {
  display: none;
}

#divProcessing {
  position: absolute;
  top: 200px;
  left: 55%;
}

.component-title {
  font-size: 16px;
  font-weight: bold;
}
