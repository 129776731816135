@import './colors.scss';

body {
  .ui-growl {
    .ui-growl-message-success {
      background-color: $success-background-color;
      color: $success-color;
    }

    .ui-growl-message-info {
      background-color: $info-background-color;
      color: $info-color;
    }

    .ui-growl-message-warn {
      background-color: $warn-background-color;
      color: $warn-color;
    }

    .ui-growl-message-error {
      background-color: $error-background-color;
      color: $error-color;
    }
  }
}
