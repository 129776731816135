body {
  .ui-overlaypanel {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);

    .ui-overlaypanel-close {
      background-color: #ffffff;
      color: #373a3c;
      border: 1px solid #c7c7c7;
      padding: 0.125em;
      border-radius: 15px;
      -moz-transition: color 0.3s;
      -o-transition: color 0.3s;
      -webkit-transition: color 0.3s;
      transition: color 0.3s;

      &:hover {
        color: #0275d8;
        background-color: #ffffff;
      }
    }
  }
}
