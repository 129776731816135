body {
  .ui-button {
    border: 1px solid transparent;
    padding: 5px 10px;
    font-size: 1em;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;

    &.ui-button-standalone {
      margin: 0 10px;

      & + .ui-button-standalone {
        margin-left: 5px;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &:focus {
      outline: 0 none;
    }

    &:enabled {
      &:hover {
        outline: 0 none;
      }

      &:active {
        -moz-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      }
    }
  }

  .ui-button,
  .ui-button.ui-button-text-only {
    .ui-button-text {
      padding: 0;
    }
  }
}
