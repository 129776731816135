@import './colors.scss';

body {
  .ui-messages {
    &.ui-messages-success {
      background-color: $success-background-color;
      border-color: $success-border-color;
      color: $success-color;

      .ui-messages-close {
        color: $success-color;
      }
    }

    &.ui-messages-info {
      background-color: $info-background-color;
      border-color: $info-border-color;
      color: $info-color;

      .ui-messages-close {
        color: $info-color;
      }
    }

    &.ui-messages-warn {
      background-color: $success-background-color;
      border-color: $success-border-color;
      color: $warn-color;

      .ui-messages-close {
        color: $warn-color;
      }
    }

    &.ui-messages-error {
      background-color: $error-background-color;
      border-color: $error-border-color;
      color: $error-color;

      .ui-messages-close {
        color: $error-color;
      }
    }
  }
}
